<template>
  <div class="consent-page">
    <div class="consent-text">
      <h2>Izjava o privatnosti</h2>
      <br />
      <p class="text-important">
        Spika d.o.o. vodi računa o privatnosti i zaštiti podataka svojih
        klijenata i posjetitelja web stranice. Pažljivo pročitajte sljedeću
        izjavu kako biste znali koje informacije možemo prikupiti od Vas, kako
        ih sve možemo upotrijebiti i koja su Vaša prava u vezi s našom
        upotrebom.
      </p>
      <br />
      <p class="text-important subtitle">
        Načela kojima se vodimo prilikom obrade podataka:
      </p>
      <br />
      <ul>
        <li>Prikupljat ćemo podatke samo u određene i navedene svrhe</li>
        <li>
          Nećemo koristiti podatke u druge svrhe osim svrhe za koje su podaci
          prikupljeni, osim uz prethodnu suglasnost
        </li>
        <li>Nećemo prenositi podatke trećim stranama</li>
        <li>
          Podatke ćemo ažurirati nakon što primimo zahtjev za njihovom izmjenom
        </li>
        <li>
          Vaše podatke obrađivat ćemo i pohranjivati samo onoliko dugo koliko to
          zahtijeva ugovor ili zakon
        </li>
        <li>
          Primijenit ćemo visoke tehničke standarde pri čuvanju sigurnosti Vaših
          podataka
        </li>
      </ul>
      <br />
      <p class="text-important subtitle">Prikupljeni podaci koriste se za sljedeće:</p>
      <br />
      <ul>
        <li>kako bismo komunicirali s Vama</li>
        <li>kako biste mogli koristiti naše usluge</li>
        <li>
          kako bismo mogli udovoljiti Vašim zahtjevima i pružiti Vam traženu
          uslugu
        </li>
        <li>kako bismo Vam mogli slati obavijesti ili promotivne materijale</li>
        <li>kako bismo unaprijedili naše poslovanje</li>
        <li>u ostale svrhe uz Vaš pristanak</li>
      </ul>
      <br />
      <p class="subtitle">
        IP adrese i kolačići
      </p>
      <br />
      <p class="text-important">
        Pregledavanje web stranica spika.hr je anonimno i tom se prilikom ne
        prikupljaju Vaši osobni podaci kao što su osobno ime ili kontakt podaci.
      </p>
      <br />
      <p>
        Pri posjeti našoj web stranici, alat Google Analytics može prikupiti
        informacije o vašem računalu kao što je vaša IP adresa, operativni
        sustav i vrsta preglednika za korisničku analizu. Možemo dobiti
        informacije o vašoj općoj internetskoj upotrebi pomoću kolačića, koji
        pomažu poboljšati našu web stranicu i pružiti bolju i personaliziranu
        uslugu. Postavke kolačića možete mijenjati u internetskom pregledniku
        koji koristite. Ako ih onemogućite, još uvijek možete posjetiti našu web
        stranicu, no ne možemo Vam jamčiti optimalno iskustvo bez kolačića.
      </p>
      <br />
      <p>
        Imate pravo na uvid u podatke koji su o Vama pohranjeni i na koji način,
        pravo na ažuriranje tih podataka, kao i pravo na prigovor ili povlačenje
        privole u svakom trenutku. Informacije o tome možete dobiti pismenim
        putem na hello@spika.hr ili na broj telefona 0919770587.
      </p>
    </div>
  </div>
</template>
